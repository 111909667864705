import React from "react";
import Img from "gatsby-image";
import Link from "../common/Link";
import Fade from "react-reveal";
import Slider from "./Slider";

export default function Project({ project }) {
  const featImage =
    project.node.featured_media?.localFile?.childImageSharp.fluid;

  return (
    <Fade bottom distance="30px">
      <div className="w-full overflow-hidden shadow-md my-4">
        <div className="">
          {!project.node.acf.url ? (
            <div className="">
              {featImage ? (
                <Img fluid={featImage} />
              ) : (
                <Slider project={project} />
              )}
            </div>
          ) : (
            <Link to={`${project.node.acf.url}`}>
              <div className="">
                {featImage ? (
                  <Img fluid={featImage} />
                ) : (
                  <Slider project={project} />
                )}
              </div>
            </Link>
          )}
          <div className=" bg-white p-4">
            <div className="flex flex-row justify-between">
              <h3 className="text-gray-900 text-2xl font-bold mb-2 tracking-tight leading-none hover:text-gray-700">
                <Link to={`/projects/${project.node.slug}`}>
                  <div
                    dangerouslySetInnerHTML={{ __html: project.node.title }}
                  />
                </Link>
              </h3>
              <p className="text-sm text-gray-600">
                <a
                  href={`${project.node.acf.url}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {project.node.acf.url ? (
                    <span className="bg-brand text-white font-bold rounded rounded-2 px-2 py-1">
                      Besök
                    </span>
                  ) : (
                    ``
                  )}
                </a>
              </p>
            </div>
            <p className="text-md md:text-lg mb-1">
              {project.node.acf.description}
            </p>
            <p className="text-sm mb-1 block md:inline-block">
              <span className="">Kategori:</span>&nbsp;
              {project.node.acf.category.map((category, index) => (
                <span className="text-gray-600 break-words" key={index}>
                  {category}
                  {index < project.node.acf.category.length - 1
                    ? `,\u00A0`
                    : ``}
                </span>
              ))}
            </p>
            <span className="hidden md:inline">&nbsp;</span>
            <p className="mb-2 text-sm block md:inline-block">
              <span className="">Verktyg:</span>&nbsp;
              {project.node.acf.tools.map((tool, index) => (
                <span className="text-gray-600 break-words" key={index}>
                  {tool}
                  {index < project.node.acf.tools.length - 1 ? `,\u00A0` : ``}
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </Fade>
  );
}
