import React from "react";
import Img from "gatsby-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

function SliderComponent({ project }) {
  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slideImage1 =
    project.node.acf.slide_image_1.localFile.childImageSharp.fluid;

  const slideImage2 =
    project.node.acf.slide_image_2.localFile.childImageSharp.fluid;

  const slideImage3 =
    project.node.acf.slide_image_3?.localFile.childImageSharp.fluid;

  return (
    <div className="-mb-2">
      <Slider {...settings}>
        {slideImage1 ? <Img fluid={slideImage1} /> : ``}
        {slideImage2 ? <Img fluid={slideImage2} /> : ``}
        {slideImage3 ? <Img fluid={slideImage3} /> : ``}
      </Slider>
    </div>
  );
}

export default SliderComponent;
